<template>
  <div class="ui-errorAllow">
    <header class="shelfHeader">
      <NavBar :show-menu="false" :is-return-url="false" />
    </header>
    <div class="ui-errorAllow__content">
      <div class="dib ui-errorAllow__picture">
        <div />
      </div>
      <div class="dib ui-errorAllow__btn">
        <h1 class="f28 b">
          403
        </h1>
        <div class="line-height-40">
          {{ $t('lang.gles.common.noPermissionPage') }}
        </div>
        <el-button size="small" type="primary" @click="goBack">
          {{ $t('lang.gles.common.back') }}{{ $t('lang.gles.common.homepage') }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import * as types from '@/store/mutationTypes'
import NavBar from '@/views/layout/components/NavBar'

export default {
  components: {
    NavBar
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['userName'])
  },
  created() {
    this.$store.commit(types.IS_LOADING, false)
  },
  methods: {
    goBack() {
      this.$router.push('/home')
    }
  }
}
</script>

<style lang="less" scoped>
.shelfHeader {
  display: flex;
  height: 60px;
  border-bottom: 1px solid #bbbbbb;
  justify-content: space-between;
  padding: 0 20px;
}
.shelfHeader span {
  display: inline-block;
  height: 60px;
  padding: 0 15px;
  line-height: 60px;
  cursor: pointer;
  &:hover {
    background: #ccc;
  }
}
.ui-errorAllow {
  height: calc(100vh - 100px);
}
.ui-errorAllow__content {
  display: flex;
  align-items: center;
  height: 80%;
  min-height: 500px;
}
.ui-errorAllow__picture {
  width: 62.5%;
  padding-right: 156px;
  & > div {
    width: 100%;
    height: 360px;
    // background: url('~@/assets/images/bg-403.svg') no-repeat right center;
  }
}
</style>
