<template>
  <div class="nav-bar">
    <!-- logo相关 -->
    <!-- 如果是未加载菜单，需要显示logo -->
    <template v-if="!showMenu">
      <div class="headerLogo">
        <img class="logoImg GEEK" fit="contain">
      </div>
    </template>

    <template v-else>
      <!-- 显示收缩功能 -->
      <div class="nav-bar__left" @click="collapse">
        <span :class="`iconfont f18 expandNav ${menuExpand ? 'icon-menu-expand' : 'icon-menu-collapsed'}`" />
      </div>
    </template>
    <div v-if="false" class="item dib item-refresh item-drowDown" @click="doRefresh">
      <i class="f18 el-icon-refresh" />
    </div>

    <div class="nav-bar__right">
      <!-- 是否全屏 -->
      <div class="item dib item-fullScreen item-drowDown" @click="handleClick">
        <i :class="`iconfont f18 ${isFullScreen ? 'icon-tuichuquanping' : 'icon-quanping'}`" />
      </div>

      <div v-loading="isExit" class="item dib item-user">
        <el-dropdown trigger="hover" placement="bottom" class="item-drowDown" @command="handleCommand">
          <p class="dib vm">
            <span class="el-dropdown-link poi mr10 f16 vm">
              {{ userName }}
            </span>
          </p>
          <el-dropdown-menu slot="dropdown" class="navbar-menu">
            <el-dropdown-item command="changepw" icon="iconfont icon-changepwd ml5">
              {{ $t('lang.gles.common.changePassword') }}
            </el-dropdown-item>
            <el-dropdown-item v-loading="isExit" command="toLogout" icon="iconfont icon-logout ml5">
              {{ $t('lang.gles.common.signOut') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!--帮助文档-->
      <!-- 定制: 北新建材没有帮助文档 -->
      <div class="helpDoc" style="display: none;">
        <el-tooltip placement="bottom" effect="light">
          <div slot="content" class="helpPop">
            <br>
            {{ $t('这里是帮助文档, 如果后面没有, 请让前端同学隐藏') }}
            <el-button type="primary" size="mini" class="download" @click="downloadHelpDoc">
              {{ $t('下载') }}
            </el-button>
          </div>
          <el-button icon="el-icon-question">
            {{ $t('帮助') }}
          </el-button>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import * as types from '@/store/mutationTypes'
import eventBus from '@/libs_sz/utils/eventBus'
// import HelpDoc from 'static/Help.doc';
import myConfig from '@/utils/config'

const LOGOUT = 'logout'
const ADMIN = '/admin' // 是不是首页
const CREATE = '/create'
const EDIT = '/edit'
const NEWFLOW = '/flow/new'

export default {
  name: 'NavBar',
  props: {
    showMenu: {
      type: Boolean,
      default: true
    },
    // 是否退出加上returnUrl
    isReturnUrl: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      selectedStoreIndex: 0,
      isExit: false,
      isFullScreen: false
    }
  },
  computed: {
    ...mapState(['userName', 'menuData', 'menuExpand']),
    ...mapGetters(['stores', 'routeInfo']),
    title() {
      return ~this.routeInfo.path.indexOf(NEWFLOW) ? this.$t('lang.ark.fed.newWorkflow') : ''
    }
  },
  watch: {
    $route(to, form) {
      const current = this.menuData.find((i) => i.path === to.path)
      let breadData = []
      breadData = [].concat(current, this.findBread(current.pid)).reverse()
      if (current.path !== '/home') {
        breadData = [{ title: this.$t('lang.gles.common.homepage') }, ...breadData]
      }
      this.breadData = breadData
    }
  },
  created() {
    // 更新route信息
    this[types.ROUTE_INFO](this.$route)
  },
  mounted() {
    // console.log(this.$route.path);
  },
  methods: {
    ...mapActions(['setStoreIndex', 'toLogout']),
    ...mapMutations([types.ROUTE_INFO, types.MENU_COLLAPSE]),
    findBread(pid) {
      const arr = []
      const parent = this.menuData.find((i) => i.id === pid)
      if (!parent) return arr
      arr.push(parent)
      if (String(parent.pid) === '-1') {
        return arr
      }
      arr.concat(this.findBread(parent.pid))
      return arr
    },
    // 用户操作
    configCommand(command) {
      if (command === LOGOUT) {
        this.$router.push('/Login')
      }
    },
    collapse() {
      this[types.MENU_COLLAPSE]()
    },
    // 修改密码
    changepw() {
      // 修改密码
      window.open(myConfig.changepwUrl)
    },
    // 跳转到首页
    toAdmin() {
      this.$router.push(ADMIN)
    },
    // 是不是首页
    noReturnBtn(path) {
      return path === ADMIN || path === CREATE || path === EDIT || path === NEWFLOW
    },
    // 下载帮助文档
    downloadHelpDoc() {
      window.location.href = `/static/极智嘉SLAM搬运系统软件操作手册.docx?v=${new Date().getTime()}`
    },
    // 处理指令
    handleCommand(command) {
      if (command === 'toLogout') {
        this.isExit = true
      }
      this[command](command === 'toLogout' ? { isReturnUrl: this.isReturnUrl } : '')
    },
    doRefresh() {
      eventBus.$emit('doRefresh')
    },
    handleClick() {
      this.isFullScreen ? this.exitFullscreen() : this.fullScreen()
    },
    fullScreen() {
      const ele = document.querySelector('body')
      if (ele.requestFullscreen) {
        ele.requestFullscreen()
      } else if (ele.mozRequestFullScreen) {
        ele.mozRequestFullScreen()
      } else if (ele.webkitRequestFullscreen) {
        ele.webkitRequestFullscreen()
      } else if (ele.msRequestFullscreen) {
        ele.msRequestFullscreen()
      }
    },
    exitFullscreen() {
      if (document.exitFullScreen) {
        document.exitFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.f42 {
  font-size: 42px;
}
</style>

<style>
.nav-bar {
  position: relative;
  height: 60px;
  width: 100%;
  line-height: 60px;
  font-size: 30px;
  background: #ffffff;
  color: #333333;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #bbbbbb;
}
.expandNav {
  display: block;
  height: 100%;
  padding: 0 10px;
  color: #666;
}
.ui-logout {
  display: inline-block;
  height: 100%;
  color: #409eff;
  padding: 0 10px;
}
.expandNav:hover,
.ui-logout:hover {
  background: rgba(0, 0, 0, 0.025);
  cursor: pointer;
}
.helpDoc {
  display: inline-block;
  margin-right: 10px;
}
.helpPop .download {
  width: 100%;
  margin-top: 10px;
}
.point {
  cursor: pointer;
}
.headerLogo {
  height: 60px;
  width: 150px;
  position: relative;
}

.logoImg.GEEK {
  height: 30px;
}

.logoImg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80%;
}

/* .item-drowDown {
  position: absolute;
  left: 80px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
} */
</style>

<style lang="scss" scoped>
.nav-bar__right {
  flex: 1;
  text-align: right;
  position: relative;
  font-size: 0;
  .item-drowDown {
    padding: 0 15px;
  }
  .item {
    height: 59px;
    vertical-align: top;
    &:hover {
      background: rgba(0, 0, 0, 0.025);
      cursor: pointer;
    }
  }
}
</style>
